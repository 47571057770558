import * as React from "react";

import { FormSpec, localized, multiSelectStylePicker } from "../../../form-specs";
import { PageWidgetSpec, ResultPanelWidgetSpec, Widget } from "../../";

import { Static } from "./Static";
import { WidgetGroup } from "../../widget.enum";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface LocalizedWidgetOptions {
    locale: string;
    content: string;
}

export interface WidgetOptions {
    styleIds: any[];
    localized: LocalizedWidgetOptions[];
}

export const TARGETS = ["static", "static-model"];

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "static-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetStatic, "staticWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetStatic, "staticWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "general"),
                    properties: [
                        [
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "content",
                                        label: getI18nLocaleObject(namespaceList.widgetStatic, "content"),
                                        type: "richtext",
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [[multiSelectStylePicker("styleIds", TARGETS)]],
                },
            ],
        },
    ],
};

export const staticWidget: PageWidgetSpec<WidgetOptions> = {
    id: "static",
    type: "page",
    widgetGroup: WidgetGroup.CONTENT,
    name: getI18nLocaleObject(namespaceList.widgetStatic, "staticWidget"),
    description: getI18nLocaleObject(namespaceList.widgetStatic, "staticWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        styleIds: [],
        localized: [],
    }),
    container: false,
    async render(widget: Widget<WidgetOptions>, context) {
        return <Static options={widget.options} context={context} />;
    },
};

export const crpStaticWidget: ResultPanelWidgetSpec<WidgetOptions> = {
    ...staticWidget,
    type: "resultsPanel",
    widgetGroup: WidgetGroup.CONTENT,
};
