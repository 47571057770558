import * as React from "react";

import { TARGETS, WidgetOptions } from "./";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import draftToHtml from "draftjs-to-html";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleString } from "../../../i18n";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import loadable from "@loadable/component";
import { loadableRetry } from "../../../utils/loadableComponents.util";
import namespaceList from "../../../i18n/namespaceList";
import { setOpacityOnHide } from "../../../components/utils";

interface StaticWidgetProps {
    options: WidgetOptions;
    context: CMSProvidedProperties;
}
const Localized = loadable(() => loadableRetry(() => import("../../../containers/Localized")), {
    resolveComponent: ({ Localized }) => Localized,
});
export const Static = (props: StaticWidgetProps) => {
    const { context, options } = props;
    const { currentLocale, site } = context;
    const { styleIds } = options;
    const hideWidget = setOpacityOnHide(options);
    const localContent = getLocalizedContent({ currentLocale, site, localizedContent: options.localized || [] });
    let html: string;
    if (localContent?.content?.startsWith('{"blocks"') || localContent?.content?.startsWith('{"entityMap"')) {
        const contentStr = localContent.content;
        try {
            const contentRaw = JSON.parse(contentStr);
            html = draftToHtml(contentRaw);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error("static content parse error", contentStr, e);
            return <Localized key={getI18nLocaleString(namespaceList.widgetStatic, "failedToLoadContent")} />;
        }
    } else {
        html = localContent?.content || "";
    }
    const className = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);
    return <div className={`static ${className} ${hideWidget}`} dangerouslySetInnerHTML={{ __html: html }} />;
};
